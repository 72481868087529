
<template>
  <div v-if="isExport">
    <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
  </div>
</template>

<script>
import pdfFile from "../../components/files/invoiceFile.vue";
import transactionApi from "@/api/transaction/";
export default {
  components: {
    pdfFile,
  },
  data: () => ({
    dataExport: [],
    loadingExport: false,
    isExport: false,
  }),
  async created() {
    let responseData = [];
    responseData = await transactionApi.transaction.getOne(
      this.$route.query.id
    );
    if (responseData.response_status == "SUCCESS") {
      this.dataExport = responseData.data;
      this.loadingExport = true;
      this.isExport = true;
    }
  },
  methods: {
    generatedFile() {
      this.isExport = false;
      window.close();
    },
  },
};
</script>